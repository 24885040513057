exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-daftar-js": () => import("./../../../src/pages/daftar.js" /* webpackChunkName: "component---src-pages-daftar-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-how-to-file-a-complaint-js": () => import("./../../../src/pages/how-to-file-a-complaint.js" /* webpackChunkName: "component---src-pages-how-to-file-a-complaint-js" */),
  "component---src-pages-index-bm-js": () => import("./../../../src/pages/index.bm.js" /* webpackChunkName: "component---src-pages-index-bm-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-labur-js": () => import("./../../../src/pages/labur.js" /* webpackChunkName: "component---src-pages-labur-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-scam-alert-js": () => import("./../../../src/pages/scam-alert.js" /* webpackChunkName: "component---src-pages-scam-alert-js" */),
  "component---src-pages-seminar-bm-js": () => import("./../../../src/pages/seminar.bm.js" /* webpackChunkName: "component---src-pages-seminar-bm-js" */),
  "component---src-pages-seminar-js": () => import("./../../../src/pages/seminar.js" /* webpackChunkName: "component---src-pages-seminar-js" */),
  "component---src-pages-sidrecs-faq-js": () => import("./../../../src/pages/sidrecs-faq.js" /* webpackChunkName: "component---src-pages-sidrecs-faq-js" */),
  "component---src-pages-speaker-ahmad-ramzani-ramli-js": () => import("./../../../src/pages/speaker/ahmad-ramzani-ramli.js" /* webpackChunkName: "component---src-pages-speaker-ahmad-ramzani-ramli-js" */),
  "component---src-pages-speaker-azhar-js": () => import("./../../../src/pages/speaker/azhar.js" /* webpackChunkName: "component---src-pages-speaker-azhar-js" */),
  "component---src-pages-speaker-dato-dr-nazri-khan-js": () => import("./../../../src/pages/speaker/dato-dr-nazri-khan.js" /* webpackChunkName: "component---src-pages-speaker-dato-dr-nazri-khan-js" */),
  "component---src-pages-speaker-haniff-js": () => import("./../../../src/pages/speaker/haniff.js" /* webpackChunkName: "component---src-pages-speaker-haniff-js" */),
  "component---src-pages-speaker-heddy-humaizi-hussain-js": () => import("./../../../src/pages/speaker/heddy-humaizi-hussain.js" /* webpackChunkName: "component---src-pages-speaker-heddy-humaizi-hussain-js" */),
  "component---src-pages-speaker-mashi-js": () => import("./../../../src/pages/speaker/mashi.js" /* webpackChunkName: "component---src-pages-speaker-mashi-js" */),
  "component---src-pages-speaker-nik-ihsan-raja-abdullah-js": () => import("./../../../src/pages/speaker/nik-ihsan-raja-abdullah.js" /* webpackChunkName: "component---src-pages-speaker-nik-ihsan-raja-abdullah-js" */),
  "component---src-pages-speaker-umran-zahini-rahman-js": () => import("./../../../src/pages/speaker/umran-zahini-rahman.js" /* webpackChunkName: "component---src-pages-speaker-umran-zahini-rahman-js" */),
  "component---src-pages-speakers-js": () => import("./../../../src/pages/speakers.js" /* webpackChunkName: "component---src-pages-speakers-js" */),
  "component---src-pages-stock-js": () => import("./../../../src/pages/stock.js" /* webpackChunkName: "component---src-pages-stock-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-post-js-content-file-path-contents-10-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/10.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-10-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-11-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/11.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-11-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-12-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/12.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-12-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-13-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/13.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-13-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-14-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/14.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-14-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-15-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/15.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-15-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-16-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/16.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-16-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-17-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/17.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-17-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-18-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/18.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-18-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-19-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/19.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-19-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-2-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/2.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-2-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-20-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/20.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-20-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-21-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/21.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-21-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-22-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/22.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-22-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-23-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/23.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-23-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-24-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/24.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-24-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-3-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/3.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-3-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-4-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/4.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-4-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-5-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/5.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-5-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-6-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/6.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-6-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-7-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/7.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-7-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-8-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/8.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-8-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-9-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/9.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-9-mdx" */),
  "component---src-templates-post-list-js-content-file-path-contents-6-mdx": () => import("./../../../src/templates/postList.js?__contentFilePath=/vercel/path0/contents/6.mdx" /* webpackChunkName: "component---src-templates-post-list-js-content-file-path-contents-6-mdx" */),
  "component---src-templates-post-list-js-content-file-path-contents-7-mdx": () => import("./../../../src/templates/postList.js?__contentFilePath=/vercel/path0/contents/7.mdx" /* webpackChunkName: "component---src-templates-post-list-js-content-file-path-contents-7-mdx" */),
  "component---src-templates-post-list-js-content-file-path-contents-8-mdx": () => import("./../../../src/templates/postList.js?__contentFilePath=/vercel/path0/contents/8.mdx" /* webpackChunkName: "component---src-templates-post-list-js-content-file-path-contents-8-mdx" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

